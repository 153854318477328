import React from "react";
import { Img, Text } from "components";

export default function CardDetail({ logo, cardName, additionLogo, children }) {
  return (
    <div className="w-full gap-5 flex-col p-5 sm:p-2 h-[15rem] sm:h-[10rem] bg-black-900 text-white-a700_99 rounded-lg">
      <div>
        <Img className="w-[48px] h-[48px]" src={logo} alt={cardName}/>
      </div>
      <div className="flex h-[60%] gap-5 flex-row sm:flex-col items-end">
        {children}
        <div className="flex flex-col w-full gap-5 items-end justify-end" style={{ height: "inherit" }}>
          <Img src={additionLogo} className=" w-[50%] sm:w-[48px] sm:h-[48px]" />
          <Text className="text-white-a700_99 text-xs">{cardName}</Text>
        </div>
      </div>
    </div>
  );
}
