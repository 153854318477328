import React, {useCallback, useState, useEffect, useRef} from "react";
import SectionTitle from "../SectionTitle";
import { useTranslation } from "react-i18next";
import useEmblaCarousel from "embla-carousel-react";
import AwardsViewWidget from "../../pages/Dashboard/Coin/AwardsView";

const HomeAwards = () => {
  const { t } = useTranslation();

  const [selectedIndex, setSelectedIndex] = useState(0);

  const [emblaRef, emblaApi] = useEmblaCarousel({
    align: "center",
    containScroll: "trimSnaps",
    loop: false,
  });

  const onSelect = useCallback((emblaApi) => {
    setSelectedIndex(emblaApi.selectedScrollSnap());
  }, []);

  useEffect(() => {
    if (!emblaApi) {
      return;
    }

    emblaApi.on("reInit", onSelect);
    emblaApi.on("select", onSelect);
  }, [emblaApi, onSelect]);

  const BTCReff = useRef(null);

  return (
    <div className="container mx-auto max-w-screen-xl px-5 mt-10 mb-20">
      <SectionTitle>{t("home.section5.title")}</SectionTitle>
      <div className="mx-auto my-10 lg:w-2/3">
        <div ref={emblaRef} className="overflow-hidden">
          <div className="flex my-10 gap-4 w-full justify-center">
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Forex Copy Trading Platform 2023.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Forex Copy Trading Platform 2023
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56  h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Trading Conditions Asia 2021.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Trading Conditions Asia 2021
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Forex Broker India 2023.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Forex Broker India 2023
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best ECN Broker 2023.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best ECN Broker 2023
                </div>
              </div>
            </div>
            <div className="flex flex-col">
              <div className=" w-56 h-max rounded-lg overflow-hidden shadow-lg p-2">
                  <AwardsViewWidget
                     imgSrc="images/awards/Best Trading Platform 2022.png"
                  >
                  </AwardsViewWidget>
              </div>
              <div className="flex flex-auto bg-slate-300 justify-center text-center text-sm h-20 ">
                <div className="p-4 font-semibold">
                  Best Trading Platform 2022
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-auto justify-center">
          <div className="">
            <a href="/Awards" className="underline font-semibold hover:text-blue-500">View More Rewards</a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAwards;
