import Paragraph from '../typography/Paragraph';

export default function CardHeader({ leftTitle, rightTitle, rightTitleIcon, status }) {
  return (
    <div className='flex justify-between'>
      <Paragraph color="black" size='large' className='font-semibold '>
        {leftTitle}
      </Paragraph>
      {(status != 'missinginfo') ?
        <div className='flex items-center'>
          <Paragraph
            color='black'
            size='large'
            className='font-semibold flex items-center space-x-2'
          >
            <span>{rightTitle}</span>
            {rightTitleIcon}
          </Paragraph>
        </div> :
        <div className='flex items-center'>
          <Paragraph
            color='black'
            size='large'
            className='font-semibold flex items-center space-x-2'
          >
            <span>N/A</span>
          </Paragraph>
        </div>
      }
    </div>
  );
}
