import React, { useEffect, useState } from "react";

export default function TradingViewChart({ containerRef, type = "crypto" }) {
  const [isChartGenerated, setIsChartGenerated] = useState(false);
  useEffect(() => {
    if (!isChartGenerated) {
      if (type === "crypto") {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
        script.innerHTML = JSON.stringify({
          symbols: [
            ["COINBASE:BTCUSD|1D"],
            ["COINBASE:ETHUSD|1D"],
            ["COINBASE:DOTUSD|1D"],
            ["CRYPTO:HTUSD|1D"],
            ["COINBASE:XRPUSD|1D"],
            ["COINBASE:DOGEUSD|1D"],
            ["BINANCE:BNBUSD|1D"],
            ["BINANCE:TRXUSD|1D"],
            ["COINBASE:SOLUSD|1D"],
            ["COINBASE:USDTUSD|1D"],
          ],
          chartOnly: false,
          width: "100%",
          // height: "100%",
          locale: "en",
          colorTheme: "dark",
          autosize: true,
          showVolume: false,
          showMA: false,
          hideDateRanges: false,
          hideMarketStatus: false,
          hideSymbolLogo: false,
          scalePosition: "right",
          scaleMode: "Normal",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          fontSize: "10",
          noTimeScale: false,
          valuesTracking: "1",
          changeMode: "price-and-percent",
          chartType: "area",
          maLineColor: "#2962FF",
          maLineWidth: 1,
          maLength: 9,
          headerFontSize: "medium",
          lineWidth: 2,
          lineType: 0,
          dateRanges: ["1d|1", "1m|30", "3m|60", "12m|1D", "60m|1W", "all|1M"],
        });
        containerRef.current.appendChild(script);
        setIsChartGenerated(true);
      } else {
        const script = document.createElement("script");
        script.type = "text/javascript";
        script.async = true;
        script.src =
          "https://s3.tradingview.com/external-embedding/embed-widget-symbol-overview.js";
        script.innerHTML = JSON.stringify({
          symbols: [
            ["FRED:SP500|1D"],
            ["CAPITALCOM:DXY|1D"],
            ["CAPITALCOM:VIX|1D"],
            ["XETR:DAX|1D"],
            ["HSI:HSI|1D"],
            ["INDEX:CAC40|1D"],
            ["FRED:NIKKEI225|1D"],
            ["NASDAQ:NDX|1D"],
          ],
          chartOnly: false,
          width: "100%",
          // height: "100%",
          locale: "en",
          colorTheme: "dark",
          autosize: true,
          showVolume: false,
          showMA: false,
          hideDateRanges: false,
          hideMarketStatus: false,
          hideSymbolLogo: false,
          scalePosition: "right",
          scaleMode: "Normal",
          fontFamily:
            "-apple-system, BlinkMacSystemFont, Trebuchet MS, Roboto, Ubuntu, sans-serif",
          fontSize: "10",
          noTimeScale: false,
          valuesTracking: "1",
          changeMode: "price-and-percent",
          chartType: "area",
          maLineColor: "#2962FF",
          maLineWidth: 1,
          maLength: 9,
          headerFontSize: "medium",
          lineWidth: 2,
          lineType: 0,
          dateRanges: ["1d|1", "1m|30", "3m|60", "12m|1D", "60m|1W", "all|1M"],
        });
        containerRef.current.appendChild(script);
        setIsChartGenerated(true);
      }
    }
  });
  return <div ref={containerRef}></div>;
}
