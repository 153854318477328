import React, { useEffect, useState } from "react";
import { MdArrowDropDown } from "react-icons/md";
import { useTranslation } from "react-i18next";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import useAuth from "hooks/zustand/useAuth";
import { shallow } from "zustand/shallow";
import { Link, useNavigate } from "react-router-dom";
import i18next from "i18next";
import {
  HiOutlineLibrary,
  HiOutlineLockClosed,
  HiOutlineMail,
} from "react-icons/hi";
import { Text } from "components";
import Button from "../button/Button";
import { set } from "react-hook-form";

const MenuDropDown = ({ value }) => {
  const userInfo = usePersonalInformation(
    (state) => ({ user: state.user, reset: state.reset }),
    shallow
  );
  const auth = useAuth();
  const navigate = useNavigate();
  const [isDropDownVisible, setDropDownVisible] = useState(false);
  const { t } = useTranslation();
  const toggleDropDown = () => {
    setDropDownVisible(!isDropDownVisible);
  };
  const handleLogout = () => {
    userInfo.reset();
    auth.logout();
    navigate(`/${i18next.language}/sign-in`);
  };

  return (
    <div className="flex flex-col border-2 rounded-xl justify-end items-end w-[50%] sm:w-full">
      <div className="flex flex-row w-full">
        <div className="flex w-full " >
        <input
          type="text"
          value={value}
          readOnly
          className="border-0 h-[2rem] focus:ring-0 focus:cursor-pointer w-full"
          onBlur={() => {
            setDropDownVisible(false);
          }}
        />
        <button
          onClick={toggleDropDown}
          className=" cursor-pointer items-end justify-end "
        >
          <MdArrowDropDown />
        </button>
        </div>
       
      </div>
      {isDropDownVisible && (
        <div className="absolute top-[3rem] flex flex-col w-full items-end">
          <ul className="list-item p-3 m-0 bg-white-a700_99 shadow  z-30 w-[13rem] rounded-b-lg">
            <li className="p-1 cursor-pointer">
              <Link
                to="/dashboard/email"
                onClick={() => {
                  setDropDownVisible(false);
                }}
              >
                <div className="flex flex-row w-full justify-end ">
                  <div className="flex justify-center items-center">
                    <HiOutlineMail />
                  </div>
                  <Text className="w-1/2">{t("member.menu.Email")}</Text>
                </div>
              </Link>
            </li>
            <li className="p-1 cursor-pointer">
              <Link
                to="/dashboard/password"
                onClick={() => {
                  setDropDownVisible(false);
                }}
              >
                <div className="flex flex-row w-full justify-end ">
                  <div className="flex justify-center items-center">
                    <HiOutlineLockClosed />
                  </div>
                  <Text className="w-1/2">{t("member.menu.Password")}</Text>
                </div>
              </Link>
            </li>
            <li className="p-1 cursor-pointer">
              <Link
                to="/dashboard/bank-information"
                onClick={() => {
                  setDropDownVisible(false);
                }}
              >
                <div className="flex flex-row w-full justify-end ">
                  <div className="flex justify-center items-center">
                    <HiOutlineLibrary />
                  </div>
                  <Text className="w-4/5">
                    {t("member.menu.Bank Information")}
                  </Text>
                </div>
              </Link>
            </li>
            <li className="p-1 cursor-pointer" onClick={handleLogout}>
              {t("member.menu.Logout")}
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};

export default MenuDropDown;
