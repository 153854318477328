import React, { useRef, useEffect, useState } from "react";
import Card from "components/dashboard/card/Card";
import CardHeader from "components/dashboard/card/Card.header";
import BadgeActive from "components/dashboard/badge/badge.active";
import BadgeSuspend from "components/dashboard/badge/badge.suspend";
import { Heading, Img, Paragraph, Text } from "components";
import Button from "components/dashboard/button/Button";
import { Link } from "react-router-dom";
import usePersonalInformation from "hooks/zustand/usePersonalInformation";
import toRinggit from "../../../lib/currencyFormatter";
import TradingViewWidget from "../Coin/TradingView";
import TodayTrade from "components/dashboard/card/Card.today-trade";
import Spinner from "components/dashboard/fallback/Spinner";
import ModalDepositUser from "../Modal/Modal.deposit";
import useLocalStorage from "hooks/useLocalStorage";
import TabNavigationSmall from "components/dashboard/tabs/Tabs-navigation";
import { useTranslation } from "react-i18next";
import CardDetail from "./CardDetail";
import TradingViewChart from "./TradingViewChart";
import MarketWatchList from "./MarketWatchList";

const TabList = ["Crypto", "Share Index"];

export default function Dashboard() {
  const userInfo = usePersonalInformation();
  const [open, setOpen] = useState(false);
  const [attempt, setAttempt] = useLocalStorage("withdrawalAttempt", 0);
  const [status, setStatus] = useState(userInfo?.user.status || "online");
  const { t, i18n } = useTranslation();
  const [tabSelected, setTabSelected] = useState({
    currentTab: 0,
    noTab: TabList.length,
  });
  const maxSuspendAttempts =
    userInfo?.user?.maxSuspendAttempts ??
    process.env.REACT_APP_DEFAULT_MAX_SUSPENDED_ATEMPTS;

  useEffect(() => {
    const controller = new AbortController();
    async function initialize() {
      await userInfo.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();
    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    async function initialize() {
      await userInfo.getTodayTrade(controller.signal);
    }
    if (userInfo.user._id) initialize();

    return () => {
      controller.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user._id]);

  useEffect(() => {
    if (
      userInfo?.user?.status === "online" ||
      userInfo?.user?.status === "active"
    ) {
      //setAttempt(0);
      const attemptPayload = {
        suspendedAttempts: 0,
        userStatus: "online",
      };
      const res = userInfo.updateUserSuspendedAttempts(attemptPayload);
    }

    if (
      userInfo?.user.status === "suspend" &&
      userInfo?.user.suspendedAttempts >= maxSuspendAttempts
    ) {
      setStatus("suspend");
    } else {
      setStatus(
        userInfo?.user.status !== "suspend" ? userInfo?.user.status : "online"
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userInfo.user.status]);

  const cryptoReff = useRef(null);
  const watchListReff = useRef(null);
  return (
    <div >
      <div className="flex flex-row w-1/2 sm:w-full">
      <Paragraph
        size="large"
        color="black"
        className="font-semibold flex items-center space-x-2 gap-4"
      >
        {t("member.dashboard.Dashboard")}{" "}
        {status === "online" || status === "active" ? (
          <BadgeActive />
        ) : status === "suspend" ? (
          <BadgeSuspend />
        ) : (
          <Spinner />
        )}
      </Paragraph>
      <div className="flex flex-col w-full justify-end items-end">
      <div className="flex flex-row gap-1">
      <Button
              radius="full"
              className="py-3 flex flex-col justify-center items-center text-white-a700_99 text-base font-semibold w-[5rem] h-[2rem]"
              backgroundColor="primary"
              onClick={() => setOpen(true)}
            >
              {t("member.dashboard.Deposit")}
            </Button>
            <Link to="/dashboard/withdraw">
              <Button
                radius="full"
                className="py-3 text-white-a700_99 flex flex-col justify-center items-center  text-base font-semibold w-[7rem] h-[2rem]"
                backgroundColor="primary"
              >
                {t("member.dashboard.Withdrawal")}
              </Button>
            </Link>
      </div>
     
      </div>
      
      </div>
      
      <div className="grid sm:grid-cols-1 grid-cols-2 h-full lg:h-card-height gap-5 py-4 lg:py-0">
        <Card className="flex justify-between flex-col space-y-6">
          <div >
            <div className="flex flex-col">
              <Paragraph color="black" size="large" className="uppercase font-semibold">{userInfo?.user?.firstName}</Paragraph>
              </div>
              <div className="w-full grid grid-cols-2 gap-5 pt-5">
            <CardDetail
              logo={`/images/dashboard/img_thumbs_up_grayscale_white_0.svg`}
              additionLogo={`/images/dashboard/img_television_text_color_black.svg`}
              cardName={t("member.dashboard.Investment Wallet")}
            >
              <div>
                {status !== "missinginfo"
                  ? toRinggit(
                      userInfo?.user.deposit +
                        (userInfo?.user?.profitLoss ?? 0) -
                        (userInfo?.user?.withdrawal ?? 0)
                    )
                  : "N/A"}
              </div>
            </CardDetail>
            <CardDetail
              cardName={t("member.dashboard.Deposit")}
              logo={`/images/dashboard/img_mobile.svg`}
              additionLogo={`/images/dashboard/img_chart.png`}
            >
              <div>
                {status !== "missinginfo"
                  ? toRinggit(userInfo?.user.deposit ?? 0)
                  : "N/A"}
              </div>
            </CardDetail>
          </div>
          </div>
        
          <div className="w-full grid grid-cols-2 gap-5 pb-10" >
            <CardDetail
              cardName={t("member.dashboard.Profit/(Loss)")}
              logo={`/images/dashboard/img_thumbs_up_secondary_yellowgreen.svg`}
              additionLogo={`/images/dashboard/img_chart_text_color_black.png`}
            >
              <div>
                {status !== "missinginfo"
                  ? toRinggit(userInfo?.user?.profitLoss ?? 0)
                  : "N/A"}
              </div>
            </CardDetail>
            <CardDetail
              cardName={t("member.dashboard.Total Withdrawal")}
              logo={`/images/dashboard/img_fire.svg`}
              additionLogo={`/images/dashboard/img_chart_text_color_black_36x56.png`}
            >
              <div>
                {status !== "missinginfo"
                  ? toRinggit(userInfo?.user?.withdrawal ?? 0)
                  : "N/A"}
              </div>
            </CardDetail>
          </div>

          {/* BOTTOM CARD */}
          {/* <div className="w-full h-max grid grid-cols-1 lg:grid-cols-2 gap-5 py-1 px-3">
            <Button
              radius="full"
              className="py-3 text-white-a700_99 text-base font-semibold "
              backgroundColor="primary"
              onClick={() => setOpen(true)}
            >
              {t("member.dashboard.Deposit")}
            </Button>
            <Link to="/dashboard/withdraw">
              <Button
                radius="full"
                className="py-3 text-white-a700_99 text-base font-semibold"
                backgroundColor="primary"
              >
                {t("member.dashboard.Withdrawal")}
              </Button>
            </Link>
          </div> */}
        </Card>
        <Card  className="!h-75vh sm:!h-[50vh] bg-yellow-100">
          {/* TOP CARD */}
          <CardHeader leftTitle={t("member.dashboard.Today Trade")} />
          <div className="h-5/6  overflow-y-auto">
            {status !== "missinginfo"
              ? userInfo?.todayTrade?.length > 0 &&
                userInfo?.todayTrade?.map((a, b) => {
                  return (
                    <Link key={b} to={`/dashboard/profile-detail/${a._id}`}>
                      <TodayTrade
                        member={userInfo?.user?.phoneNumber}
                        logo={a.product.coinId}
                        profile={`${a.product.coinId} ${a.profileDesc}`}
                        // profitLoss={a.profitLossAmount + a.investAmount}
                        investAmount={a.investAmount}
                        profitLoss={a.profitLossAmount}
                        changingTime={a.profitChangingTime}
                        percentage={(
                          (a.profitLossAmount / a.investAmount) *
                          100
                        ).toFixed(1)}
                        isProfit={a.isProfit}
                        isPlay={a.isPlay}
                        playDate={a.playDate}
                        finishPlayDate={a.finishPlayDate}
                      />
                    </Link>
                  );
                })
              : null}
          </div>
        </Card>
      </div>
      <div className="w-full">
        <Card backgroundColor="secondary" className="">
          {/* TOP CARD */}
          <CardHeader
            leftTitle={t("member.dashboard.Cryptocurrency Performance")}
          />
          {/* MIDDLE CARD */}
          <section className="max-w-full h-[30rem] " aria-multiselectable={false}>
            <div>
              <ul className="flex items-center w-full" role="tablist">
                {TabList.map((value, index) => {
                  return (
                    <TabNavigationSmall
                      key={index}
                      tabSelected={tabSelected}
                      setTabSelected={setTabSelected}
                      tabNum={index}
                      label={value}
                    />
                  );
                })}
              </ul>
            </div>
            {/* <div className=" grid h-2/3 overflow-y-auto ">
              {tabSelected.currentTab === 0 && (
                <div className="w-full">
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/bitcoin"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:BTCUSD"
                      containerRef={BTCReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/ethereum"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:ETHUSD"
                      containerRef={ETHReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/polkadot"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:DOTUSD"
                      containerRef={DOTReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/houbitoken"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="CRYPTO:HTUSD"
                      containerRef={HTReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/ripple"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="COINBASE:XRPUSD"
                      containerRef={XRPReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/dogecoin"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="BINANCE:DOGEUSD"
                      containerRef={DOGEReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/binance"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="BINANCE:BNBUSD"
                      containerRef={BNBReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/tron"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="BINANCE:TRXUSD"
                      containerRef={TRXReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/solana"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="BINANCE:SOLUSD"
                      containerRef={SOLReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/usdt"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="GEMINI:USDTUSD"
                      containerRef={USDTReff}
                    />
                  </div>
                </div>
              )}
              {tabSelected.currentTab === 1 && (
                <div className="w-full">
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/sp500"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="FRED:SP500"
                      containerRef={SPXReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/usdollarindex"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="CAPITALCOM:DXY"
                      containerRef={DXYReff}
                    />
                  </div>
                  <div className="w-full  relative">
                    <Link
                      to="/dashboard/volatility"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="CAPITALCOM:VIX"
                      containerRef={VIXReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/dax"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="XETR:DAX"
                      containerRef={DAXReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/hsi"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="HSI:HSI"
                      containerRef={HSIReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/cac40"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="INDEX:CAC40"
                      containerRef={CAC40Reff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/nikkei"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="FRED:NIKKEI225"
                      containerRef={NIKKEIReff}
                    />
                  </div>
                  <div className="w-full relative">
                    <Link
                      to="/dashboard/nasdaq"
                      className="absolute w-full h-full z-infinity"
                    ></Link>
                    <TradingViewWidget
                      symbol="NASDAQ:NDX"
                      containerRef={NASDAQReff}
                    />
                  </div>
                </div>
              )}
            </div> */}
            <div className=" grid h-full overflow-y-auto ">
              {tabSelected.currentTab === 0 && (
                <div>
                  <TradingViewChart containerRef={cryptoReff} />
                </div>
              )}
              {tabSelected.currentTab === 1 && (
                <div>
                  <TradingViewChart containerRef={cryptoReff} type="shares" />
                </div>
              )}
            </div>
          </section>
        </Card>
      </div>
      <div className="mt-3 flex flex-col w-full h-[50rem] bg-card-secondary rounded-lg">
          <Text className="py-5 px-3 font-semibold text-base text-[#181c32]">Market Watch List</Text> 
          <MarketWatchList containerRef={watchListReff} />
        
      </div>
      <ModalDepositUser open={open} close={() => setOpen(false)} />
    </div>
  );
}
