import {React} from "react";
import Layout from "../components/Layout";
import Carousel from "../components/Carousel";
import HomeTrusted from "../components/HomePage/HomeTrusted";
import HomeInvest from "../components/HomePage/HomeInvest";
import HomeStarted from "../components/HomePage/HomeStarted";
import HomeTestimoni from "../components/HomePage/HomeTestimoni";
import HomeSignUp from "../components/HomePage/HomeSignUp";
import HomeAwards from "../components/HomePage/HomeAwards";
import HomeBuild from "../components/HomePage/HomeBuild";
// mock data
//import { mockSliders } from "../mock/mockData";


const Home = () => {
  return (
    <Layout>
      <Carousel />

      <HomeTrusted />

      <HomeInvest />

      <HomeBuild />

      <HomeStarted />

      <HomeTestimoni />

      {/* <HomeAwards /> */}

      <HomeSignUp />
    </Layout>
  );
};

export default Home;
