import ModalUserInfo from "../../../components/dashboard/modal/modal.user-info";
import Spacing from "../../../components/dashboard/spacing/Spacing";
import Paragraph from "../../../components/dashboard/typography/Paragraph";
import Button from "../../../components/dashboard/button/Button";

export default function ModalDepositUser({ open, close }) {
  return (
    <ModalUserInfo open={open} setOpen={close} title="Deposit" className="p-4">
      <Spacing />
      <div className="flex flex-col bg-white-a700_99 text-center">
        <Paragraph size="medium" padding="none" color="black">
          This is currently under development. Please contact your broker to
          deposit.
        </Paragraph>
        <div className="flex justify-end mt-5">
          <Button
            type="button"
            className="w-max px-5 py-2 text-primary text-sm font-semibold"
            radius="full"
            backgroundColor="cancel"
            onClick={() => {
              close();
            }}
          >
            Close
          </Button>
        </div>
      </div>
      <Spacing />
    </ModalUserInfo>
  );
}
