import React, { useEffect } from "react";

export default function MarketWatchList ({
  containerRef
}){
  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-market-quotes.js';
    script.innerHTML = JSON.stringify({
      width: "100%",
      height: "100%",
      showSymbolLogo: true, 
      isTransparent: false, 
      colorTheme: "dark",
      backgroundColor: "#131722",
      symbolsGroups:[
        {
          name: "Indices",
          originalName: "Indices",
          symbols:[
            {name: "FOREXCOM:SPXUSD", displayName: "S&P 500 Index"},
            {name: "INDEX:NKY", displayName: "Nikkei 225"},
            {name: "XETR:DAX", displayName: "DAX Index"},
            {name: "CAPITALCOM:DXY",displayName: "US Dollar Index"},
            {name: "CAPITALCOM:VIX",displayName: "Volatility Index"},
            {name: "HSI:HSI",displayName: "Hang Seng Index"},
            {name: "INDEX:CAC40",displayName: "CAC 40 Index"},
            {name: "NASDAQ:NDX",displayName: "Nasdaq 100 Index"}
          ]
        },
        {
          name: "Crypto",
          symbols:[
            {name: "COINBASE:BTCUSD", displayName: "BitCoin"},
            {name: "COINBASE:ETHUSD",displayName: "Ethereum"},
            {name: "COINBASE:DOTUSD", displayName: "Polkadot"},
            {name: "CRYPTO:HTUSD",displayName: "Houbitoken"},
            {name: "BINANCE:XRPUSD",displayName: "Ripple"},
            {name: "COINBASE:DOGEUSD",displayName: "Dogecoin"},
            {name: "BINANCE:BNBUSD",displayName: "Binance"},
            {name: "BINANCE:TRXUSD",displayName: "Tron"},
            {name: "COINBASE:SOLUSD",displayName: "Solana"},
            {name: "COINBASE:USDTUSD",displayName: "USDT"}
          ]
        }
      ]
    });
    if (containerRef){
      containerRef.current.appendChild(script);
    }
  }, []);
  return (
      <div className="h-[50rem]" ref={containerRef}></div>
  )  
}